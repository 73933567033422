:root {
  --color-primary: 0, 181, 255;
  --color-secondary: 0, 215, 255;
  --color-accent: 0, 126, 178;
  --font-color: 80, 80, 80;
  --color-black: 30, 30, 30;
  --color-white: 255, 255, 255;
  --color-dark-grey: 134, 134, 134;
  --shadow-1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  --toastify-color-progress-success: rgb(var(--color-white)) !important;
  --toastify-color-progress-error: rgb(var(--color-white)) !important;
  --toastify-text-color-success: rgb(var(--color-white)) !important;
  --toastify-text-color-error: rgb(var(--color-white)) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px; 9px/16px = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%;
  }
  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  font-family: "Fira sans", sans-serif;
  color: rgb(var(--font-color));
  margin: 0;

  @include respond(tab-land) {
    padding: 0;
  }
}

.App {
  overflow-x: hidden;
}
