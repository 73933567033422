.primary-color {
  color: rgb(var(--color-primary));
}

.hexagon {
  display: inline-block;
  position: relative;
  width: 10rem;
  height: 8.66rem;
  box-sizing: border-box;

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
    -webkit-clip-path: polygon(
      20% 0,
      80% 0,
      100% 50%,
      80% 100%,
      20% 100%,
      0% 50%
    );

    background: rgb(var(--color-white));
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 100%;
      height: auto;
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(
      60deg,
      rgba(var(--color-accent), 0.95) 25%,
      rgba(var(--color-primary), 0.75) 50%,
      rgba(var(--color-secondary), 0.85) 75%
    );
    background-size: 200% 100%;
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
    z-index: 1;
    clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
    -webkit-clip-path: polygon(
      20% 0,
      80% 0,
      100% 50%,
      80% 100%,
      20% 100%,
      0% 50%
    );
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3),
      0 0 15px rgba(var(--color-accent), 0.7);

    animation: pulse 2.25s ease infinite;
  }
}

.mt-2 {
  padding-top: 2rem;
}

.relative {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.dots {
  display: inline-block;
  width: 10px;
  overflow: hidden;
  vertical-align: bottom;
  animation: ellipsis 1.5s infinite;
  font-size: 1.5rem;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-items: center;
}

.align-center {
  align-items: center;
}
