.Toastify__toast-theme--colored {
  &__toast--success {
    background-color: rgb(var(--color-primary)) !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: rgba(226, 10, 39, 0.867) !important;
}

.Toastify {
  &__toast {
    &-container {
      width: max-content !important;
      max-width: var(--toastify-toast-width) !important;
    }
    &-body {
      font-size: 1.5rem;
      font-family: inherit !important;
      font-weight: 400 !important;
    }
    &-icon {
      width: max-content !important;
      max-width: 2rem !important;
    }
    &--success {
      background: rgb(var(--color-primary)) !important;
      color: rgb(var(--color-white)) !important;
    }
    &--error {
      background: red !important;
      color: rgb(var(--color-white)) !important;
    }
  }
}

.btn {
  &,
  &:link,
  &:visited {
    min-width: 15rem;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.5);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(var(--color-black), 0.2);
     background-color: rgb(var(--color-white));
      color: rgb(var(--color-primary));
  }

  &--primary {
    background-color: rgb(var(--color-primary));
    color: rgb(var(--color-white));

    &::after {
      background-color: rgb(var(--color-white));
      color: rgb(var(--color-primary));
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}

.project-card {
  min-width: 22rem;
  width: 40rem;

  @include respond(tab-port) {
    width: 90%;
  }

  &__content {
    width: 100%;
    position: relative;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    border-radius: 1.6rem;
  }

  &__icons-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 4rem;
    height: 4rem;
    cursor: pointer;

    & .icon {
      height: 2.5rem;
      width: 2.5rem;
      fill: rgb(var(--color-black));
      transition: fill 1.25s ease 1.245s linear;
      transition: height 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s linear;

      &:hover {
        fill: rgb(var(--color-primary));
        height: 2.75rem;
        width: 2.75rem;
      }
    }
  }

  &__name {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: normal;
  }

  &__description {
    font-size: 1.4rem;
    color: rgba(var(--color-black), 0.75);
    margin-bottom: 0.8rem;

    @include respond(tab-port) {
      font-size: 1.5rem;
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin-top: 1.4rem;
  }

  &__tag {
    font-size: 1.4rem;
  }
}

.form {
  &__group {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    background-color: rgba(var(--color-white), 0.7);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    caret-color: rgb(var(--color-primary));
    transition: all 0.3s;

    @include respond(tab-port) {
      width: 100%;
    }

    &:focus {
      outline: none;
      // box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.1);
      box-shadow: var(--shadow-1);
      border-bottom: 3px solid rgb(var(--color-primary));
    }

    &:focus:invalid {
      border-bottom: 3px solid rgb(var(--color-accent));
    }

    &::-webkit-input-placeholder,
    &::placeholder {
      color: rgb(var(--color-dark-grey));
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(4rem);
  }

  &__btn {
    background-color: rgb(var(--color-accent));
    transition: background 0.8s ease 0.5s;
    @include respond(phone) {
      width: 100%;
    }

    &:hover {
      background: radial-gradient(
        circle at center,
        rgb(var(--color-secondary)),
        rgb(var(--color-primary))
      );
    }
    &:disabled {
      background: radial-gradient(
        circle at center,
        rgba(var(--color-dark-grey), 0.5),
        rgba(var(--color-dark-grey), 0.75)
      );
      box-shadow: none;
    }
  }
}

.stars-canvas {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: auto;
  z-index: -1;
}
