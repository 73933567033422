@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes ellipsis {
    0% { width: 0; }
    25% { width: 4px; }
    50% { width: 8px; }
    75% { width: 12px; }
    100% { width: 16px; }
}

@keyframes pulse {
  0%{
    background-position: 200% 0;
  }
  100%{
    background-position: -200% 0;
  }
}

