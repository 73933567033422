//MEDIA QUERY MANAGER
/*
0-600px: Phone
600-900px: Tablet portrait
900-1200px: Tablet landscape
[1200-1800]is where out normal styles apply
1800px + : Big desktop

ORDER: Base + typography > general layout + grid > page layout > components

$breakpoint argument choices::before
-phone
-tab-port
-tab-land
-big-desktop
*/


@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {  //600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) { //900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) { //1200px
      @content;
    }
  }
  @if $breakpoint == big-desktop { //1800px
    @media (min-width: 112.5em) {
      @content;
    }
  }
}