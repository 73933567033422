.heading{
    font-size:5rem;
    font-weight: 900;
    letter-spacing: 0.25rem;
    white-space: nowrap;
    margin-bottom:1rem;
    line-height: normal;
}

.sub-heading{
    font-size:2rem;
    font-weight: 300;
    text-transform: uppercase;
    line-height:normal;
}

.paragraph{
    font-size:1.8rem;
    font-weight: 400;
    margin-bottom:2rem;
    line-height: 1.5;
}